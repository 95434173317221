<template>
  <div>
    <BreadCrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />

    <v-card :loading="loading">
      <v-card-title> {{ title }} </v-card-title>

      <div class="pa-5">
        <v-row no-gutters>
          <v-col>
            <p>Logomarca</p>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col>
                <v-img :src="logoSrc" max-width="150"></v-img>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col md="3">
                <v-file-input
                  v-model="file"
                  ref="file"
                  show-size
                  label="Adicionar arquivo:"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Nome"
              v-model="form.institution_name"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Cidade"
              v-model="form.city_address"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Estado"
              v-model="form.state_address"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Endereço"
              v-model="form.address"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Bairro"
              v-model="form.district_address"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Número"
              v-model="form.number_address"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Complemento"
              v-model="form.complement_address"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Telefone"
              v-model="form.phone"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="E-Mail"
              v-model="form.email"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn class="primary darken-1" @click="save()" :loading="loading">
              <v-icon left>mdi-content-save</v-icon> Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>

      <v-dialog v-model="successDialog" max-width="400">
        <v-card>
          <v-card-text>
            <div class="text-center pa-4">
              <v-icon large color="success">mdi-check</v-icon>
            </div>

            <div class="text-center pa-3">
              <h3>Operação realizada com sucesso</h3>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeSuccessDialog()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import logoImage from "@/assets/default-image.png";
import BreadCrumbs from "@/components/template/Breadcrumbs.vue";
import { baseUrl } from "@/config/config.json";
import { mapState } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      default: null,
    },
  },
  components: {
    BreadCrumbs,
  },
  data: () => ({
    logoSrc: logoImage,
    loading: false,
    successDialog: false,
    file: null,
    form: {
      number_address: "S/N",
    },
  }),
  computed: {
    ...mapState("user", ["userData"]),
    institutionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    validateForm() {
      const errors = [];

      if (!this.form.institution_name)
        errors.push("O campo NOME é obrigatório");

      if (!this.form.city_address) errors.push("O campo CIDADE é obrigatório");

      if (!this.form.state_address) errors.push("O campo ESTADO é obrigatório");

      if (!this.form.address) errors.push("O campo ENDEREÇO é obrigatório");

      if (!this.form.district_address)
        errors.push("O campo BAIRRO é obrigatório");

      if (!this.form.number_address)
        errors.push("O campo NÚMERO é obrigatório");

      if (!this.form.phone) errors.push("O campo TELEFONE é obrigatório");

      if (!this.form.email) errors.push("O campo E-MAIL é obrigatório");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mode]();
    },
    async insert() {
      try {
        this.loading = true;

        let formData = new FormData();

        formData.append("file", this.file);

        let dataToSend = { ...this.form };

        for (const key of Object.keys(dataToSend)) {
          if (dataToSend[key]) formData.append(key, dataToSend[key]);
        }

        await this.$axios.post(`/admin/instituicoes`, formData);

        this.clearForm();

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      }finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        let formData = new FormData();

        formData.append("file", this.file);

        let dataToSend = { ...this.form };

        for (const key of Object.keys(dataToSend)) {
          if (dataToSend[key]) formData.append(key, dataToSend[key]);
        }

        await this.$axios.put(
          `/admin/instituicoes/${this.institutionId}`,
          formData
        );

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    closeSuccessDialog() {
      this.successDialog = false;

      if (this.mode === "update") this.initializeForm();
      else this.$router.go(-1);
    },
    clearForm() {
      this.form = {
        number_address: "s/n",
      };
    },
    async initializeForm() {
      try {
        this.loading = true;

        const res = await this.$axios.get(
          `/admin/instituicoes/${this.institutionId}`
        );

        this.form = { ...res.data };

        if (this.form.logo) {
          this.logoSrc = `${baseUrl}/uploads/institutions/${this.form.logo}`;
        }
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  mounted() {
    if (this.mode === "update") this.initializeForm();
  },
};
</script>

<style>
.required label::after {
  content: " *";
  color: red;
}
</style>
